import Vue from 'vue'
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
	state: {
		isLogin: false,
        userInfo: null,
	},
    mutations: {
        Login(state, info) {
            state.isLogin = true
            state.userInfo = info
        },
        Logout(state){ // 退出登录
            state.userInfo = {}
            state.isLogin = false
            localStorage.token = null;
            sessionStorage.token = null;
        }
    },
    actions: {}
})